import mediumZoom from "medium-zoom";

const zoom = mediumZoom("[data-zoomable]", { margin: 15 });

zoom.on("open", () => {
  document.querySelector(".projects").style.opacity = 0;
});

zoom.on("close", () => {
  document.querySelector(".projects").style.opacity = 1;
});

let images = [...document.querySelectorAll(".project__image")];

const onIntersection = (imageEntities) => {
  imageEntities.forEach((image) => {
    if (image.isIntersecting) {
      observer.unobserve(image.target);
      image.target.srcset = image.target.dataset.srcset;
    }
  });
};

let observer = new IntersectionObserver(onIntersection, {
  rootMargin: `0px 0px ${window.innerHeight}px 0px`,
});
images.forEach((image) => observer.observe(image));
