import './_images.js'
import './_projects.js'

document.querySelector('.video').addEventListener('mouseenter', e => {
  document.querySelector('.background-video').classList.add('is-active')
  document.body.classList.add('video-active')
})

document.querySelector('.video').addEventListener('mouseleave', e => {
  document.querySelector('.background-video').classList.remove('is-active')
  document.body.classList.remove('video-active')
})

if (window.innerWidth < 768) {
  document.querySelectorAll('video').forEach(video => video.remove())
}