let projects = [...document.querySelectorAll('.project')];


const contentsActiveState = projectIndex => {
  document.querySelectorAll('.contents__list__item').forEach((item, index) => {
    projectIndex === index
      ? item.classList.add('is-active')
      : item.classList.remove('is-active')
  })
}

const onIntersection = projectEntities => {
  projectEntities.forEach(project => {
    if (project.isIntersecting) {
      document.querySelector('.video').style.background = project.target.dataset.background
      document.body.style.background = project.target.dataset.background
      document.body.style.transition = `background 1s ease-in-out`
      contentsActiveState(projects.indexOf(project.target))
    }
  })
}

let observer = new IntersectionObserver(onIntersection, { rootMargin: `0px 0px 0px 0px` });
projects.forEach(project => observer.observe(project));